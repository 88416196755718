import React from "react"
import styled, { createGlobalStyle } from "styled-components"
import { Link } from "gatsby"

const GlobalStyle = createGlobalStyle`
  @font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: local('Roboto'), url('/public/fonts/Roboto-Regular.ttf') format('truetype');
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
*:focus {
  outline: 0;
}
html, body, #root {
  height: 100%;
  scroll-padding-top: 125px;
}
body {
  -webkit-font-smoothing: antialiased;
}
body, input, button {
  font: 14px 'Roboto', sans-serif;
}
a {
  text-decoration: none;
}
ul {
  list-style: none;
}
button {
  cursor: pointer;
  border: 0;
  padding: 0;
}
`

const Container = styled.div`
  background: #e61c2b;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;

  h1 {
    color: #fff;
    font-size: 48px;
  }

  h2 {
    color: #fff;
    font-size: 36px;
  }

  a {
    margin-top: 40px;
    color: #e9e9e9;
    text-decoration: underline;
  }
`

export default () => {
  return (
    <Container>
      <GlobalStyle />
      <h1>Error: 404</h1>
      <h2>Page not found</h2>
      <Link to="/">Clique aqui para retornar para a página principal</Link>
    </Container>
  )
}
